import { useContext, useRef } from 'react'
import { usePathname } from 'next/navigation'
import { SVGIcon } from '../SVGIcon'
import { useUser } from '../../../hook/useUser'
import { ApiResponseContext } from '../../../contexts/ApiResponseContext'
import { ModalContext } from '../../../contexts/ModalContext'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { ToastContext } from '../../../contexts/ToastContext'
import { BOOKMARKS_LIMIT } from '../../../constants/bookmark'
import { theme } from '../../../styles'
import {
  GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_BOOKMARK,
  SUBSCRIBED_BOOKMARK_PARAM,
} from '../../../constants/gigya'

export function Bookmark({ className }: { className?: string }): JSX.Element {
  const { userProfile, updateUser, loading, isLoggedIn } = useUser()
  const asPath = usePathname()
  const { page } = useContext(ApiResponseContext)
  const { setToast } = useContext(ToastContext)
  const { setModal } = useContext(ModalContext)
  const bookMarkRef = useRef(null)
  const loadingMark = useRef(false)
  const { hit } = useContext(TagCommanderContext)

  const { id, h1 = '', config } = page || {}
  const subscriptionConfig = config?.subscription || {}
  const hasBookmark = userProfile?.data?.Tf1Info?.bookmarks?.includes(id) || false
  const title = !hasBookmark
    ? `Sauvegarder l'article ${h1}`
    : `Retirer l'article ${h1} des sauvegarde`

  const handleOnClick = () => {
    if (!loading && userProfile && !loadingMark.current) {
      loadingMark.current = true
      const bookmarks = userProfile?.data?.Tf1Info?.bookmarks ?? []

      const index = bookmarks.findIndex((bookmark) => bookmark === id)
      if (index !== -1) {
        bookmarks.splice(index, 1)
        updateUser({
          data: { Tf1Info: { bookmarks: [...bookmarks] } },
        })
      } else {
        if (bookmarks.length + 1 > BOOKMARKS_LIMIT) bookmarks.pop()
        updateUser({
          data: { Tf1Info: { bookmarks: [id, ...bookmarks] } },
        })
      }

      hit(
        {
          screen_clickableElementName:
            index === -1 ? 'header_bookmarked_article' : 'header_unbookmarked_article',
        },
        {
          isClickEvent: true,
        },
      )

      setToast({
        title: !hasBookmark ? 'Article sauvegardé' : 'Article retiré',
      })
      loadingMark.current = false
    }
    if (!loading && !isLoggedIn) {
      hit(
        { screen_clickableElementName: 'header_bookmarked_article_unlogged' },
        { isClickEvent: true },
      )
      setModal({
        ref: bookMarkRef,
        text: subscriptionConfig?.text,
        src: subscriptionConfig?.image,
        linkLogin: {
          label: subscriptionConfig?.buttonLogin,
          href: `/compte/connexion/?redirect=${asPath}&serviceType=${GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_BOOKMARK}&${SUBSCRIBED_BOOKMARK_PARAM}=${id}`,
        },
        linkRegistration: {
          label: subscriptionConfig?.buttonRegistration,
          href: `/compte/inscription/?redirect=${asPath}&serviceType=${GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_BOOKMARK}&${SUBSCRIBED_BOOKMARK_PARAM}=${id}`,
        },
        tmsClose: 'header_bookmarked_croix-fermeture',
        tmsLogin: 'header_bookmarked_se-connecter',
        tmsRegistration: 'header_bookmarked_creer-un-compte',
      })
    }
  }

  return (
    <button
      className={`flex${className ? ` ${className}` : ''}`}
      onClick={handleOnClick}
      title={title}
      ref={bookMarkRef}
    >
      {hasBookmark ? (
        <SVGIcon name="bookmark-full" size={24} primaryColor={theme.cssVars.white} />
      ) : (
        <SVGIcon name="bookmark" size={24} primaryColor={theme.cssVars.white} />
      )}
    </button>
  )
}
