import { useAmp } from 'next/amp'
import { useContext } from 'react'

import { useCMP } from '../../../hook/useCMP'
import { isServer } from '../../../helpers/client'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { RESEAUX_SOCIAUX } from '../../../constants/cmp'
import { Icon } from '../../atoms/Icon'

export interface IShare {
  tmsId?: string
  backgroundColor?: string
  size?: string
  primaryColor?: string
  withPlaceholder?: boolean
  className?: string
}

export const Share = ({
  tmsId = 'bouton_partage',
  withPlaceholder,
  ...props
}: IShare): JSX.Element => {
  const { hasCorrectConsent } = useCMP([RESEAUX_SOCIAUX])
  const isAmp = useAmp()
  const { hit } = useContext(TagCommanderContext)

  if (isAmp || (!isServer() && !navigator.share) || !hasCorrectConsent) {
    return (
      <div
        style={withPlaceholder ? { width: props.size, height: props.size } : {}}
        className={props.className || ''}
      ></div>
    )
  }

  const handleTms = (): void => {
    hit(
      {
        screen_clickableElementName: tmsId,
      },
      { isClickEvent: true },
    )
  }

  const handleShare = async () => {
    handleTms()
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          url: window.location.href,
        })
      } catch (e) {
        return
      }
    } else {
      return
    }
  }

  return (
    <>
      <Icon name="share" onClick={handleShare} {...props} />
    </>
  )
}
